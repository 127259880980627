<template>
  <!-- <div class="d-inline-block" v-if="user"> -->
  <div v-if="user">
    <v-hover v-slot:default="{ hover }">
      <v-avatar :tile="tile" :color="color" class="profile-background-cover" :size="size" @click="selectUser()">
        <template v-if="!photoUrl">
          <!-- <span class="white--text font-weight-bold" :style="{ fontSize: size/3 + 'px' }">{{ getInitial(user.fullName) }}</span> -->
          <span class="white--text font-weight-bold" :style="{ fontSize: size/3 + 'px' }">No Profile Cover</span>
        </template>
        <template v-else>
          <v-img :src="photoUrl" :alt="`${user.fullName}`"></v-img>
        </template>

        <v-overlay v-if="hover && editable" absolute>
          <v-btn icon @click="openDialog()">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-overlay>
      </v-avatar>
    </v-hover>

    <template v-if="editable">
      <v-dialog
        v-model="status.uploadPhotoBackgroundDialog"
        max-width="450"
        persistent
      >
        <v-card>
          <v-card-title class="font-weight-bold">Upload Photo <v-chip dense small class="ml-4">recommend: 1440px by 343px</v-chip></v-card-title>
          <v-card-text>
            <v-form ref="photoForm">
              <v-progress-linear
                v-if="status.uploadProgress" 
                :value="status.uploadProgress"
                height="4"
                color="primary"
                class="mb-2"
                reactive
              ></v-progress-linear>

              <v-file-input
                v-model="data.profileBackground"
                placeholder="Select Photo"
                prepend-icon=""
                prepend-inner-icon="mdi-account-box"
                accept="image/*"
                ref="photoInput"
                :rules="[rules.required]"
                outlined
              ></v-file-input>

              <v-btn
                @click="validateForm()"
                :loading="status.uploadingPhoto"
                color="accent white--text"
                class="mr-2 gradient"
                depressed
              >Upload</v-btn>

              <v-btn
                @click="closeDialog()"
                :disabled="status.uploadingPhoto"
                depressed
              >Cancel</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import db from '@/firebase/init'
import firebase from 'firebase'
import rules from '@/rules'

export default {
  name: 'UserPhotoCover',

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: {
    id: String,
    editable: Boolean,
    size: Number,
    tile: Boolean,
    photoSize: {
      type: String,
      default: 'large'
    },
    enableSelection: Boolean,
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      photoUrl: null,
      user: null,
      color: null,
      colors: [
        // 'pink',
        // 'purple',
        // 'deep-purple',
        // 'indigo',
        // 'blue',
        // 'light-blue',
        // 'cyan',
        // 'teal',
        // 'green',
        // 'light-green',
        // 'orange',
        // 'deep-orange',
        // 'blue-grey',
        'grey'
      ]
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      data: state => state.user.data,
      status: state => state.user.status,
      currentUser: state => state.user.user,
      profile: state => state.profile.profile,
    })
  },

  /*------------------------------------------------------------------------------
   * WATCH
   *----------------------------------------------------------------------------*/
  watch: {
    'currentUser.profileBackground': function () {
      this.getUrl(this.id)
    },

    'id': function (val) {
      this.getUrl(val)
    },
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('user', [
      'uploadProfileBackground'
    ]),

    getInitial(name) {
      let nameArray = name.split("")
      return nameArray[0]
    },

    openDialog() {
      this.$store.commit('user/setUploadPhotoBackgroundDialog', true)
    },

    closeDialog() {
      this.$store.commit('user/setUploadPhotoBackgroundDialog', false)
      this.$refs.photoForm.reset()
    },

    validateForm() {
      if (this.$refs.photoForm.validate()) {
        this.uploadProfileBackground()
      }
    },

    getUrl(id) {
      db.collection('users').where('userid', '==', id)
      .limit(1)
      .get()
      .then(snapshot => {
        if (snapshot.size) {
          this.photoUrl = null
          let data = snapshot.docs[0]
          this.user = data.data()
          this.user.id = data.id
          this.user.ref = data.ref

          if (this.user.profileBackground) {
            var storage  = firebase.storage()

            // storage.ref(`profilephotos/${this.photoSize}_${this.user.avatar}`).getDownloadURL()
            storage.ref(`profilebackground/${this.user.profileBackground}`).getDownloadURL()
            .then(url => {
              this.photoUrl = url
            })
            .catch(error => {
              console.log(error.message)
              storage.ref(`profilebackground/${this.user.profileBackground}`).getDownloadURL()
              .then(url => {
                this.photoUrl = url
              })
            })
          }
        }
      })
      .catch(error => {
        this.$store.dispatch('showError', error.message)
        console.log('UserPhotoCover', error.message)
      })

    },

    getRandomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)]
    },

    selectUser() {
      if(this.enableSelection) {
        this.$emit('userSelect')
      }
    }
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    this.getUrl(this.id)
    this.color = this.getRandomColor()
  }

}

</script>


<style scoped lang="scss">
  .profile-background-cover{
    width: 100% !Important;
    max-height: 150px !Important;
  }
</style>